import { motion } from 'framer-motion';
import Link from 'next/link';
import { Dispatch, SetStateAction } from 'react';
import { FiChevronDown, FiExternalLink } from 'react-icons/fi';
import { LoginType } from '../../../../types';
import Bilhold from '../../../icons/Bilhold';
import Icon, { Icons } from '../../../icons/Icon';

interface LogInProps {
  isMenuOpen: boolean;
  logins: LoginType[];
  setIsLoginOpen: Dispatch<SetStateAction<boolean>>;
  isLoginOpen: boolean;
}

const dropdownVariant = {
  open: {
    height: 'auto',
  },
  closed: {
    height: 0,
  },
};

function LogIn({ isMenuOpen, logins, setIsLoginOpen, isLoginOpen }: LogInProps) {
  return (
    <li className="relative mb-6">
      <button
        type="button"
        className="flex w-full items-center justify-between gap-4 py-2 active:opacity-50"
        tabIndex={isMenuOpen ? 0 : -1}
        onClick={() => {
          setIsLoginOpen((prev) => !prev);
        }}
      >
        <div className="flex items-center gap-4">
          <Icon icon={Icons.PERSON} className=" bg-surface-variant h-8 w-8 rounded-lg p-1.5" />
          Logg inn
        </div>

        <FiChevronDown
          className="h-6 w-6 transition-transform duration-300"
          style={isLoginOpen ? { transform: 'rotateX(180deg)' } : {}}
        />
      </button>

      <div className="bg-background/30 absolute -inset-x-6 -bottom-5 h-px sm:-inset-x-10" />

      <motion.ul
        initial="closed"
        animate={isLoginOpen ? 'open' : 'closed'}
        variants={dropdownVariant}
        className="overflow-hidden p-1"
      >
        <div className=" flex flex-col pt-4">
          {logins.map((login) => {
            return (
              <Link
                href={login.cta.url}
                key={login.title}
                tabIndex={isLoginOpen ? 0 : -1}
                className="border-background/30 flex items-center gap-4 border-t p-2 py-4 active:opacity-50 "
              >
                {login.title.toLowerCase().includes('bilhold') ? (
                  <Bilhold className="" />
                ) : (
                  <FiExternalLink className="" />
                )}
                <span className=" line-clamp-1">{login.title}</span>
              </Link>
            );
          })}
        </div>
      </motion.ul>
    </li>
  );
}

export default LogIn;
